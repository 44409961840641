/* Custom Variables */

$unregistered-color: #faf49b;
$primary-color: #FFC139;
$secondary-color: #f5f5f5;
$body-color: #505050;
$hover-color: #d32f2f;
$title-color: #111111;
$white-color: #ffffff;

/* Font Awesome Overrides */

.latest-news-slider .slick-arrow:after {
  font-family: "Font Awesome 6 Free";
}

.testimonial-section h3.to-title:after {
  font-family: "Font Awesome 6 Free";
}

.testimonil-section-page .single-testimonil .author-info h4:after {
  font-family: "Font Awesome 6 Free";
}

.single-product-page .single-product-area .single-product-nav .slick-prev::before,
.single-product-page .single-product-area .single-product-nav .slick-next::before {
  font-family: "Font Awesome 6 Free";
}

.footer-section .footer-top .sitemap-widget li a:after {
  font-family: "Font Awesome 6 Free";
}

#return-to-top::before {
  font-family: "Font Awesome 6 Free";
}

.rs-breadcrumbs ul li a:after {
  font-family: "Font Awesome 6 Free";
}

/* Custom/Override CSS */

ul.disc {
  list-style: disc outside none;
  margin: 1.5em 4em;
}

ol.alpha {
  list-style-type: lower-alpha;

  li {
    padding: 0.1em 0.8em;
  }
}

.rs-breadcrumbs {
  max-height: 360px;

  img#header-image {
    top: -225px;
    position: relative;
  }
}

.event-current,
#no-events-msg {
  display: none;
}


.card-event {
  margin-bottom: 2rem;

  .card-header {
    background-color: #FFC139;
  }

  h4 {
    margin-bottom: 5px
  }
}

a.recent-news-icon {
  color: #505050
}

.sidebar-area {
  .recent-post-area {
    margin-top: 0px;
  }

  .standings-box {
    margin-top: 0px;

    span.title {
      margin-bottom: 10px;
    }
  }

  .card {
    .card-body {
      padding: 0
    }

    table.point-table {
      margin-bottom: 0;

      th {
        background-color: #000;
        color: #FFC139;
        text-align: left;
        padding: 10px 15px;
      }

      td {
        padding: 5px 10px;
      }
    }
  }
}

.table-standings-sidebar {
  caption {
    font-weight: bold;
  }
}

.rs-result .team-result {
  background: url(/assets/images/overlay-bg.webp);
}

.table-standings {
  border-collapse: collapse;
  margin-bottom: 50px;

  th {
    color: #FFC139;
    font-weight: bold;
    background-color: #000;

    div.vertical {
      writing-mode: vertical-lr;
      text-orientation: sideways;
      margin: 0 auto;
      padding: 5px 10px;
    }
  }

  td {
    width: 20px;
    text-align: center;
    border: 1px solid rgb(221, 221, 2211);
  }

  td.row-label {
    text-align: left;
    font-weight: bold;
  }

  th,
  td {
    font-size: 12px;
  }

  td.unsactioned {
    background-color: #888888;
  }

  tr.unregistered td,
  span.unregistered {
    background-color: $unregistered-color;
  }
}

table.stats-table {
  font-size: 0.9em;
}

div.stats-legend {
  margin-bottom: 2em;
  font-size: 0.75em;
}

div.stats-group {
  margin-bottom: 2em;
}

.card-team {
  min-height: 315px;
  margin-bottom: 1rem;

  .card-header {
    color: #FFC139;
    background-color: #000;
    font-weight: bold;
  }

  .card-footer {
    text-align: center;
    padding: 8px 0 8px 10px;
    border: none;
    background-color: #FFC139;

    a {
      color: #000;
    }
  }

  table {
    margin: 0;
  }

  table tr:first-child {
    background-color: #FFF;
  }

  table tr:first-child td {
    color: #000;
  }

  table tr td:first-child {
    padding-left: 15px;
    width: 45px;
  }

  table tr:hover {
    background-color: #FFC139;
  }
}

.slider-sections-area3 #slider-images ul li {
  max-height: 805px;
  overflow: hidden;

  .single-slide {
    top: 75%;
  }
}



.results-link-box {
  margin-bottom: 2em;
}

.header-lower-banner {
  padding: 15px 0;
  background: #FFC139;

  ul li a {
    transition: all 0.4s ease 0s;
    color: #111111;
    font-family: 'Poppins', sans-serif;
    font-size: 19px;
    font-weight: 400;
  }

  ul li a:hover {
    color: #505050;
  }
}

.champion-section-area {
  .rs-count {
    .rs-counter-list-sm {
      text-align: center;

      h2 {
        margin: 0 auto 10px;
        font-size: 15px;
        width: 60px;
        height: 60px;
        border-top: 4px solid $primary-color;
        border-bottom: 4px solid #ddd;
        line-height: 55px;
        text-align: center;
        border-radius: 50%;
      }

      h3 {
        font-size: 14px;
        font-weight: 500;
        margin: 0;
      }
    }
  }
}

.club-sidebar .club-details ul li {
  width: 25%;
}

.clicent-logo-section .single-logo {
  background-color: #FFFFFF;
}

.clicent-logo-section .single-logo:hover {
  background: #FFF;
  border: 1px solid $primary-color;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.team-stats-page {
  margin-bottom: 3rem;

  .match-summary {
    background: #f2f2f2;

    table {
      tr {
        border-top: 1px solid #e0e0e0;
        border-bottom: 1px solid #ebebeb;
        color: #fff;

        td {
          padding: 6px 1.5em;
        }

        td.full-area {
          background: $title-color !important;
        }

        td:nth-child(2n+1) {
          background-color: $primary-color;
          color: $title-color;
        }

        td:nth-child(2n) {
          color: $body-color;
        }
      }

      tr.full-area {
        background: $primary-color !important;

        td {
          background: $title-color !important;
          color: #fff;
        }
      }
    }
  }
}

.icon-row {
  margin-bottom: 2em;
}

.ui {
  width: 100%;
  height: auto;
  min-height: auto;
  z-index: 0;
}